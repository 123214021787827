import Vue from 'vue'

import App from './Form_c.vue'
import Form from './EasyForm.vue'
Vue.config.productionTip = false
new Vue({
  render: h => h(Form),
}).$mount('.easy_form')

new Vue({
  render: h => h(App),
}).$mount('.form_c')
