<template>
  <div class="consult_wrapper" data-aos="zoom-in" data-aos-delay="300">

    <div class="top">
      <p class="ttl">ご相談フォーム</p>
    </div>
    <div class="bottom">
      <p class="ttl">お客様がお住まいの物件情報や
        <br class="pc">回線導入可能エリアをお調べの上、
        <br class="pc">弊社よりご案内のお電話を差し上げます</p>
      <form>
        <validation-observer ref="observer">


          <div class="input">
            <!-- <div class="checkbox_wrap">
              <input v-model="formData.moveIn.WantGuideElec__c" name="WantGuideWater__c" type="checkbox" />
              <label>電気の契約も希望する</label>
            </div>
            <div class="checkbox_wrap">
              <input v-model="formData.moveIn.WantGuideWater__c" name="WantGuideWater__c" type="checkbox" />
              <label>水道の契約も希望する</label>

            </div>
            <div class="checkbox_wrap">
              <input v-model="formData.moveIn.WantGuideGas__c" name="WantGuideGas__c" type="checkbox">
              <label>ガスの契約も希望する</label>
            </div> -->
            <div class="consult_input">
              <validation-provider name="姓" rules="required" v-slot="{ errors }">
                <p class="error">{{ errors[0] }}</p>
                <input v-model="formData.moveIn.LastName__c" name="last_name" type="text" required="required"
                  placeholder="姓を入力" />
                
              </validation-provider>

              <validation-provider name="名" rules="required" v-slot="{ errors }">
                <p class="error">{{ errors[0] }}</p>
                <input v-model="formData.moveIn.firstName__c" name="first_name" type="text" required="required"
                  placeholder="名を入力" />
              </validation-provider>
              <validation-provider name="電話番号" mode="eager"  rules="required|phone" v-slot="{errors}">
                <p class="error">{{ errors[0] }}</p>
                <input v-model="formData.moveIn.MobilePhone__c" @input="numberFormat" name="mobile" type="text" required="required"
                  placeholder="電話番号を入力" />
              </validation-provider>
              <validation-provider name="メールアドレス" rules="email" v-slot="{errors}">
                <p class="error">{{ errors[0] }}</p>
                <input v-model="formData.moveIn.Email__c"  inputmode="email" name="Email__c" type="text" placeholder="メールアドレスを入力（任意）" />
              </validation-provider>
              <validation-provider name="住所" rules="required"  v-slot="{errors}">
                <p class="error">{{ errors[0] }}</p>
                <textarea v-model="formData.moveIn.Street__c" name="Street__c" type="text" placeholder="住所を入力" />
          </validation-provider>
                  
                </div>
                <div class="submit_btn">
                  <!-- KF追記 -->
                  <input type="button" :disabled="postbtn_disable" class="submit" @click="send" value="送信する">
                </div>
              </div>
              </validation-observer>
            </form>
            <p class="read">「<span><a href="https://classlab.co.jp/privacy/">プライバシーポリシー</a></span>」をご一読、
              <br>ご理解いただき送信してください。</p>
            <p class="n"> ※営業時間外にご連絡いただいた場合は、<br class="sp">翌営業日に対応いたします。</p>
            <a href="tel:0120-948-424" class="tel">今すぐ電話をする</a>
            <p class="number pc">0120-948-424</p>
            <div class="number_button sp ">
              <a href="tel:0120-948-424">0120-948-424</a>
            </div>
            <p class="day">平日：10:00～20:00
              <br>土日祝：10:00～18:00</p>
          </div>
        </div>
</template>
<script>
import axios from 'axios';
  import {
    PhoneNumberUtil,
    PhoneNumberFormat
  } from 'google-libphonenumber';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import ja from 'vee-validate/dist/locale/ja.json'
import { required } from "vee-validate/dist/rules"; 
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]) // eslint-disable-line
})
localize({ja});
extend('phone', {
    // エラーメッセージを設定する
    message: 'ハイフン付きで正しく入力してください',
    validate(value) {
      const mobilePattern = /^0[5789]0/;
        if (mobilePattern.test(value) && value.length != 13) {
            return false;
        }
        const pattern = /^0\d{1,3}-\d{1,4}-\d{4}$/;
        return pattern.test(value);
    }
});
extend('email', {
    // エラーメッセージを設定する
    message: 'メールアドレスの形式を確認してください',
    validate(value) {
       return  /^[a-zA-Z0-9_+-]+[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(value)
    }
});
extend("required", {
  ...required,
  message: "{_field_}は必須です"
});
export default {
  components: {
    ValidationObserver:ValidationObserver,
    ValidationProvider:ValidationProvider,
  },
    methods: {
      numberFormat() {
        const region = 'JP';
        let util = PhoneNumberUtil.getInstance();
        
        let number = null;
        try {
          number = util.parseAndKeepRawInput(this.formData.moveIn.MobilePhone__c, region);
        } catch (e) {
          return;
        }

        
        if (!util.isValidNumberForRegion(number, region)) {
          return null;
        }
        this.formData.moveIn.MobilePhone__c = util.format(number, PhoneNumberFormat.NATIONAL);

      },
      async send() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
          this.postbtn_disable = true;
          let vm = this;
          axios
              .post(process.env.VUE_APP_API_URL+'sendMoveIn', this.formData)
              .then(function (response) {
                  if (response.data == 'ok') {
                    window.location = "completion.html"
                  } else {
                    window.alert("エラーが発生しました")
                  }
              })
              .catch(function () {
                  vm.postbtn_disable = false;
              });
          }else{
             window.alert("フォームの入力項目をご確認ください")
          }
      },
  },
  created(){
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
     if(params.get('source')){
      this.formData.moveIn.IntroductionFor__c = params.get('source');
    }
  },
  data() {
    return {
      postbtn_disable:false,
      formData:{
        moveIn:{
          IntroductionFor__c:process.env.VUE_APP_ACCOUNT_ID,
          WantGuideInternet__c:true,
          WantGuideGas__c:false,
          WantGuideWater__c:false,
          SMSRejection__c:true,
          ForLP__c:true,
          WantGuideElec__c:false,
          LastName__c:"",
          firstName__c:"",
          MobilePhone__c:"",
          Street__c:"",
          Email__c:"",
        }
      }
    }
  }
}
</script>